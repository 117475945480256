.coming-soon-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #222220;
}

.coming-soon-heading {
  text-align: center;
  font-size: 6.4rem;
  font-weight: 500;
  text-transform: uppercase;
  color: #fff;
}

.social-links {
  display: flex;
  gap: 2.4rem;
  align-items: center;
  justify-content: center;
}

.icon {
  transition: all 0.3s;
}

.icon:hover {
  transform: scale(1.3);
}

.mechogne {
  width: 9.6rem;
}

@media only screen and (max-width: 600px) {
  .coming-soon-heading {
    font-size: 3.6rem !important;
  }
}
